<div class="flex justify-end items-center exportReport mb-3">
  <div class="ml-0 xl:mb-0 text-right flex items-center justify-end">
    <!-- Loading State -->
    <el-tooltip
      class="item"
      effect="dark"
      content="Báo cáo đang được hệ thống cập nhật, dữ liệu hiện tại có thể đã hết hiệu lực."
      placement="bottom"
      v-if="is_updating_report"
    >
      <i class="el-icon-loading mr-2"></i>
    </el-tooltip>

    <!-- Period select -->
    <div
      v-if="['summary', 'report_131', 'report_331'].indexOf(routeName) == -1"
      class="flex"
    >
      <div class="md:px-3 p-7px mr-1 rounded border bg-white">
        {{ declarationType == 'T' ? 'Tháng' : 'Quý' }}
      </div>
      <el-select
        v-model="report_period"
        placeholder="Select"
        style="width: 5rem"
        @change="changeDeclaration()"
      >
        <el-option
          v-for="item in rangePeriod"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
    </div>

    <!-- Year select -->
    <div class="md:px-3 p-7px mr-1 ml-1 rounded border bg-white">Năm</div>
    <el-select
      v-model="report_year"
      @change="changeYear()"
      placeholder="Select"
      style="width: 6rem"
    >
      <el-option
        v-for="(item, index) in arrayYear"
        :key="index"
        :label="item"
        :value="item"
      ></el-option>
    </el-select>
  </div>

  <!-- Export group -->
  <div class="ml-3 flex items-center justify-end">
    <!-- Excel -->
    <el-button
      size="mini"
      class="md:py-2 md:px-3 p-2 rounded border"
      @click="downloadFile('excel')"
      :type="!getIsDownloadable || getIsDownloadProcessing ? 'warning':'success'"
      :disabled="!getIsDownloadable || getIsDownloadProcessing || loadingButton"
    >
      <div class="flex items-center">
        <span v-if="!getIsDownloadable || getIsDownloadProcessing" class="el-icon-loading"></span>
        <img v-else src="@/assets/icons/icon_excel.svg" alt="" class="w-4 h-4 mr-1" />
        <span>Excel</span>
      </div>
    </el-button>

    <!-- PDF -->
    <el-button
      v-if="routeName != 'toKhaiThueGtgt' && !getIsLargePDF"
      size="mini"
      class="p-2 rounded border"
      @click="downloadFile('pdf')"
      :type="!getIsDownloadable || getIsDownloadProcessing ? 'warning':'success'"
      :disabled="!getIsDownloadable || getIsDownloadProcessing || loadingButton"
    >
      <div class="flex items-center">
        <span v-if="!getIsDownloadable || getIsDownloadProcessing" class="el-icon-loading"></span>
        <img v-else src="@/assets/icons/icon_pdf.svg" alt="" class="w-4 h-4" />
        <span>PDF</span>
      </div>
    </el-button>

    <el-tooltip
      v-if="routeName != 'toKhaiThueGtgt' && getIsLargePDF"
      class="item"
      effect="dark"
      content="Tệp PDF chỉ được hỗ trợ cho tệp < 1500 bản ghi"
      placement="bottom"
    >
      <el-button
        size="mini"
        class="p-2 rounded border"
        @click="downloadFile('pdf')"
        type="info"
        disabled
      >
        <div class="flex items-center">
          <span class="el-icon-warning"></span>
          <span>PDF</span>
        </div>
      </el-button>
    </el-tooltip>

    <!-- XML/Tax Return -->
    <div v-if="routeName == 'tax_return'" class="ml-2">
      <el-button
        size="mini"
        class="p-2 rounded border"
        @click="downloadFile('xml')"
        type="success"
        :disabled="!getIsDownloadable"
      >
        <div class="flex items-center">
          <img src="@/assets/icons/icon_xml.svg" alt="" class="w-4 h-4" />
          <span>XML</span>
        </div>
      </el-button>
    </div>
    <div v-if="routeName == 'tax_return'" class="ml-2">
      <div class="inline-block relative h-full mx-2">
        <el-popover
          placement="bottom-start"
          :width="200"
          :trigger="getTaxRefundProposal.status == 'disabled' ? 'hover' : 'manual'"
        >
          <template #reference>
            <div class="inline z-40">
              <el-button
                :type="getTaxRefundProposal.status == 'disabled' ?  'warning' : 'primary'"
                size="mini"
                class="
                  md:py-2
                  w-16
                  sm:w-auto
                  md:w-36
                  lg:w-auto
                  overflow-hidden
                  md:overflow-visible
                  h-full
                  rounded
                  border
                "
                :class="getTaxRefundProposal.status == 'disabled' ? 'btn-tax-refund': '' "
                @click="taxRefundRequest()"
                :disabled="getTaxRefundProposal.status == 'disabled'"
              >
                Đề nghị hoàn thuế GTGT
              </el-button>
            </div>
          </template>
          <div class="pop-info">{{getTaxRefundProposal.info}}</div>
        </el-popover>
      </div>

      <div class="inline-block relative">
        <el-popover
          placement="bottom-start"
          :width="200"
          :trigger="getSubmitReport.info || (getUserRole == 'CompanyUser' && getSubmitReport.status != 'show' && !(getSubmitReport.status == 'disabled' || getSubmitReport.status == 'disabled_reported')) ? 'hover' : 'manual'"
        >
          <template #reference>
            <div class="inline z-40">
              <el-button
                v-if="getSubmitReport.status == 'show' || getSubmitReport.status == 'disabled'"
                size="mini"
                :type="getSubmitReport.status == 'disabled' || getSubmitReport.status == 'disabled_reported' ?  'warning' : 'primary'"
                class="
                  md:py-2 md:px-3
                  p-2
                  w-16
                  sm:w-auto
                  overflow-x-hidden
                  md:overflow-visible
                  rounded
                  border
                "
                @click="submitReport(getSubmitReport.status)"
                :disabled="getSubmitReport.status != 'show'"
              >
                Nộp báo cáo
              </el-button>
              <el-button
                v-else
                size="mini"
                :type="getSubmitReport.status == 'disabled' || getSubmitReport.status == 'disabled_reported' ?  'warning' : 'primary'"
                class="
                  md:py-2 md:px-3
                  p-2
                  w-16
                  sm:w-auto
                  overflow-x-hidden
                  md:overflow-visible
                  rounded
                  border
                "
                @click="submitReport(getSubmitReport.status)"
                :disabled="getSubmitReport.status == 'disabled' || getSubmitReport.status == 'disabled_reported' || getUserRole == 'CompanyUser'"
              >
                Hủy báo cáo
              </el-button>
            </div>
          </template>

          <div
            v-if="getUserRole == 'CompanyUser' && !(getSubmitReport.status == 'disabled' || getSubmitReport.status == 'disabled_reported')"
            class="pop-info"
          >
            Tài khoản không đủ quyền
          </div>
          <div v-else class="pop-info">{{getSubmitReport.info}}</div>
        </el-popover>
      </div>
    </div>
  </div>
</div>
<div class="menu-top flex items-center md:mt-0">
  <el-tabs v-model="activeTabs" @tab-click="changeTabs">
    <el-tab-pane label="Bảng kê bán ra" name="output" class="w-full" />
    <el-tab-pane label="Bảng kê mua vào" name="input" class="w-full" />
    <el-tab-pane label="Tờ khai thuế GTGT" name="tax_return" class="w-full" />
    <el-tab-pane label="Tổng hợp thuế GTGT" name="summary" class="w-full" />
    <el-tab-pane label="Tổng hợp 131" name="report_131" class="w-full" />
    <el-tab-pane label="Tổng hợp 331" name="report_331" class="w-full" />
  </el-tabs>
</div>

<el-scrollbar
  ref="scrollbar"
  class="height-table"
  v-loading="getIsReportLoading"
>
  <div v-if="selectCurrentCompany">
    <router-view></router-view>
  </div>
</el-scrollbar>

<el-dialog
  title="Đề nghị hoàn thuế"
  v-model="showModalTaxRefund"
  :width="dialogWidth"
  @closed="resetFormItaxRefund()"
>
  <el-form
    label-width="180px"
    :label-position="dialogWidth == '30%' ? 'left':'top'"
    @submit.native.prevent="confirmTaxRefund()"
  >
    <el-form-item label="Thuế GTGT đề nghị hoàn">
      <input-money
        v-model.lazy="moneyTaxRefund"
        id="tax-refund-id"
        class="tax-refund"
        @inputChange="moneyTaxRefund = $event"
      />
    </el-form-item>
    <div class="text-right">
      <span class="dialog-footer itax-button">
        <el-button size="mini" @click="showModalTaxRefund = false">
          Hủy
        </el-button>
        <el-button size="mini" type="primary" native-type="submit">
          Xác nhận
        </el-button>
      </span>
    </div>
  </el-form>
</el-dialog>
